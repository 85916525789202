<template>
  <div class="membership-code">
    <el-form ref="formRef" :model="formData" :rules="rules" onsubmit="return false">
      <div class="membership-code__intro">
        <img src="@/assets/img/cheer-gomgom.png">
        <div class="membership-code__speech">JAJAK 멤버십 코드를 입력해주세요.</div>
      </div>
      <div class="membership-code__inp">
        <el-form-item prop="license_code">
          <el-input v-model="formData.license_code" placeholder="멤버십 코드를 입력해 주세요." size="large" />
        </el-form-item>
      </div>
      <div class="membership-code__tool">
        <el-button type="info" @click="emit('close')">취소</el-button>
        <el-button type="primary" @click="handleSubmit(formRef)">등록</el-button>
      </div>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import type { FormInstance } from 'element-plus';
import { registerLicense } from "~/composables/php/auth";

const emit = defineEmits(['close']);

const formRef = ref<FormInstance>();

const formData = ref({
  license_code: '',
});

const rules = ref({
  license_code: [
    { required: true, message: '멤버십 코드를 입력해 주세요.', trigger: 'blur' },
  ],
})

function handleSubmit(formEl: FormInstance) {
  formEl.validate((valid: boolean) => {
    if (valid) {
      registerLicense(formData.value).then((res) => {
        if (res.data.error) {
          ElMessage.error(res.data.message);
        } else {
          ElMessage.success(res.data.message);
          emit('close');
        }
      });
    }
  });
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/membership/code.scss';
</style>